export default function CircleTopLeft({ height, width }) {
  return (
    <svg
      id="CTL_Layer_1"
      data-name="Circle Top leftLayer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 97.2 98.36"
      height={height}
      width={width}
    >
      <defs>
        <clipPath id="ctl-clip-path" transform="translate(8.63 -29.62)">
          <rect fill="none" x="-8.63" y="29.62" width="97.2" height="98.36" />
        </clipPath>
        <clipPath id="ctl-clip-path-3" transform="translate(8.63 -29.62)">
          <rect fill="none" x="-10.43" y="35.21" width="108" height="93.77" />
        </clipPath>
        <clipPath id="ctl-clip-path-4" transform="translate(8.63 -29.62)">
          <rect fill="none" x="-17.63" y="22.62" width="65.29" height="55.95" />
        </clipPath>
      </defs>
      <g clipPath="url(#ctl-clip-path)">
        <g clipPath="url(#ctl-clip-path)">
          <g opacity="0.45">
            <g clipPath="url(#clip-path-3)">
              <path
                fill="#5b63ad"
                d="M5.11,85.09h0c0-20.9,17.39-36.86,38.67-36.86,20.6,0,38,16,38.12,36.86-.13,20.67-17.52,37-38.12,37-21.28,0-38.67-16.31-38.67-37m-6.54,0h0C-1.29,108.78,18.49,128,43.78,128c25,0,44.66-19.2,44.79-42.89-.13-23.68-19.78-42.88-44.79-42.88-25.29,0-45.07,19.2-45.21,42.88"
                transform="translate(8.63 -29.62)"
              />
            </g>
          </g>
          <g opacity="0.15">
            <g clipPath="url(#ctl-clip-path-4)">
              <path
                fill="#5b63ad"
                d="M15,77.57a24,24,0,0,0,0-47.95,24,24,0,0,0,0,47.95"
                transform="translate(8.63 -29.62)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
