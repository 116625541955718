import Search from "components/search";
import CircleTopLeft from "@/public/images/circleTopLeft";
import styles from "styles/landing.module.css";
import SearchIcon from "@/public/images/search.js";

export default function HowItWorks() {
  return (
    <div className="relative bg-quaternary mb-48">
      <div className="absolute right-[30px] -top-[49px]">
        <CircleTopLeft width={97} height={98} />
      </div>
      <div className="py-24 mx-auto max-w-screen-md px-4">
        <h4 className={`mb-8 text-4xl ${styles.boldHeader}`}>How it works</h4>

        <div className="flex mb-8">
          <div className="flex text-lg rounded-full mr-4 justify-center items-center h-10 text-gray-900 font-bold min-w-[40px] border-2 border-primary">
            1
          </div>
          <div>
            <h5 className="text-2xl">Fill out the form to start the loaning prodecure</h5>
            <span>We have this super awesome form that hopefully won't put you to sleep</span>
          </div>
        </div>

        <div className="flex mb-8">
          <div className="flex text-xl rounded-full mr-4 justify-center items-center h-10 min-w-[40px] text-gray-900 font-bold border-2 border-primary">
            2
          </div>
          <div>
            <h5 className="text-2xl">We will see if you’re eligible for instant funding</h5>
            <span>Our internal Rabbits will do some quick calulations</span>
          </div>
        </div>

        <div className="flex">
          <div className="flex text-xl rounded-full mr-4 justify-center items-center h-10 min-w-[40px] text-gray-900 font-bold border-2 border-primary">
            3
          </div>
          <div>
            <h5 className="text-2xl">You enjoy the money submitted to your bank account</h5>
            <span>Money appears in your wallet. Almost like magic... but not quite</span>
          </div>
        </div>

        <div className="mx-auto max-w-lg border-0 relative rounded-3xl -bottom-28 bg-gradient-to-b from-primary to-secondary shadow-[4px_5px_4px_#dceaf4]">
          <Search />
          <div className="absolute left-[15px] top-[10px]">
            <SearchIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
